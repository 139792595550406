<template>
  <div :class="cssClasses.holder" v-if="showComponent" @click.prevent="closeModal">
    <div :class="cssClasses.wrapper">
      <div v-if="contentWithGraph">
        <slot></slot>
      </div>
      <div>
        <a @click.prevent="closeModal" :class="cssClasses.closeButton">
          <VueIcon
            :iconName="getIcon.cross.name"
            iconColor="#ffffff"
            :width="getIcon.cross.width"
            :height="getIcon.cross.height"
          ></VueIcon>
        </a>

        <div :class="cssClasses.resultBodyTop" v-if="topTitle && topValue && !contentWithGraph">
          <VueHeadline weightLevel="3" level="2" color="white-100" :class="cssClasses.headline">{{
            topTitle
          }}</VueHeadline>
          <VueText weightLevel="4" sizeLevel="14" color="white-100" :class="cssClasses.value">{{
            topValue
          }}</VueText>
        </div>
        <hr v-if="!contentWithGraph" />

        <div
          :class="cssClasses.resultBodyBottom"
          v-if="bottomTitle && bottomValue && !contentWithGraph"
        >
          <VueHeadline weightLevel="3" level="2" color="white-100" :class="cssClasses.headline">{{
            bottomTitle
          }}</VueHeadline>
          <VueText weightLevel="4" sizeLevel="14" color="white-100" :class="cssClasses.value"
            >{{ bottomValue }} TL</VueText
          >
        </div>
        <BrandButton
          v-if="!contentWithGraph"
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          :outlined="true"
          :padding="{ vertical: 15, horizontal: 0 }"
          @click="handleSubmit"
          :class="cssClasses.submitButton"
          ><VueText sizeLevel="6" weightLevel="3" color="red-30"
            >PERFORMANS TAKİBİ</VueText
          ></BrandButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueText from '@/components/shared/VueText/VueText.vue';

export default {
  name: 'BrandResultView',
  components: {
    VueHeadline,
    BrandButton,
    VueText,
    VueIcon,
  },
  props: {
    showComponent: {
      type: Boolean,
      default: false,
    },
    cssStyles: {
      type: Object,
    },
    topTitle: {
      type: String,
    },
    topValue: {
      type: Number || String,
    },
    bottomTitle: {
      type: String,
    },
    bottomValue: {
      type: Number || String,
    },
    contentWithGraph: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    getIcon() {
      return {
        cross: ICON_VARIABLES.cross,
      };
    },
    cssClasses() {
      return {
        holder: [this.$style['component-holder']],
        wrapper: [this.$style['component-wrapper']],
        closeButton: [this.$style['close-button']],
        resultBodyTop: [this.$style['result-body-top']],
        resultBodyBottom: [this.$style['result-body-bottom']],
        headline: [this.$style['headline']],
        value: [this.$style['value']],
        submitButton: [this.$style['submit-button']],
      };
    },
    cssStyle() {
      return this.cssStyles ? { ...this.cssStyles } : null;
    },
  },
  destroyed() {
    document.documentElement.style.overflow = 'auto';
  },
  methods: {
    handleSubmit(e) {
      this.$emit('handleSubmit', e);
    },
    closeModal(e) {
      if (
        e.target.classList.contains(this.cssClasses.holder) ||
        e.currentTarget.classList.contains(this.cssClasses.closeButton)
      ) {
        this.$emit('close', e);
      }
    },
  },
};
</script>

<style module lang="scss">
.component-holder {
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(3px);

  .component-wrapper {
    width: 941px;
    padding: palette-space-level(30);
    margin: auto;
    background-color: palette-color-level('white', '100');

    .close-button {
      position: absolute;
      right: palette-space-level(30);
      top: palette-space-level(30);
      cursor: pointer;
    }

    .result-body-top,
    .result-body-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .headline {
        padding: 0 palette-space-level('50');
        line-height: 1.5;
      }
    }
    .result-body-top {
      margin-top: auto;
    }
    .result-body-bottom {
      margin-bottom: auto;
    }
  }
}
</style>
