<template>
  <div id="divTurnoverWrapper">
    <div class="content-layout-fixer">
      <div class="turnover-header">
        <div class="turnover-header-left">
          <div class="header-content">
            <VueText class="header-content-headline" sizeLevel="9">{{
              headerContent[0].headline
            }}</VueText>
            <VueText class="header-content-text" sizeLevel="6">{{ headerContent[0].text }}</VueText>
          </div>
          <!-- <div class="doughnut-chart-products">
            <div
              class="products-item"
              v-for="(item, index) in doughnutCategoryChart.labels"
              :key="item"
            >
              <div
                class="label-color"
                :style="{
                  backgroundColor:
                    doughnutCategoryChart.datasets[0].backgroundColor[index] || '#79838e',
                }"
              ></div>
              <VueText color="grey-40" sizeLevel="6" weightLevel="3" class="products-item-text">{{
                item
              }}</VueText>
            </div>
          </div> -->
        </div>
        <!-- <div class="turnover-header-right">
          <div class="header-with-chart-wrapper">
            <div class="doughnut-headline" @click="handlePopUpState('open')">
              <VueText class="doughnut-headline-text" color="grey-40" sizeLevel="5" weightLevel="3"
                >Kategorilere Göre Ciro Dağılımı</VueText
              >
              <VueIcon
                :width="icons.info.width"
                :height="icons.info.height"
                :iconName="icons.info.name"
                iconColor="#23303d"
              ></VueIcon>
            </div>
            <div class="doughnut-wrapper">
              <BrandDoughnutChart
                :key="renderKey"
                v-if="chartLoaded"
                :data="doughnutCategoryChart"
                :options="doughnutChartOptions"
                class="doughnut-chart"
              ></BrandDoughnutChart>
              <VueText class="mock-text" color="grey-40" weightLevel="3" sizeLevel="12">
                {{ getCategoryPercentage }}</VueText
              >
            </div>
          </div>
        </div> -->
      </div>
      <VueWrapperNarrow>
        <VueForm class="form-holder" ref="form">
          <div v-for="(item, index) in formModel" :key="index">
            <VueInput
              class="form-input"
              :name="item.name"
              :id="item.name"
              type="text"
              :label="item.label"
              v-model="item.amount"
              :messageOnTop="true"
              validationRules="numeric"
              validatorName="Ciro dağılımı"
            ></VueInput>
          </div>
        </VueForm>
        <BrandButton
          class="calc-button"
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          :padding="{ vertical: 15, horizontal: 0 }"
          @click="handleSubmit"
          :disabled="isDisabled"
          >HESAPLA</BrandButton
        >
      </VueWrapperNarrow>
    </div>
    <BrandResultView
      @handleSubmit="submitResultPopUp"
      @close="closeModal"
      :key="showResultView"
      :showComponent="showResultView"
      :contentWithGraph="true"
    >
      <div class="result-doughnut-headline">
        <VueText color="grey-40" sizeLevel="11" weightLevel="3"
          >Kategorilere Göre Ciro Dağılımı Yüzdesel</VueText
        >
      </div>
      <div class="result-doughnut-products-wrapper">
        <div class="result-doughnut-wrapper">
          <BrandDoughnutChart
            :key="renderKey"
            v-if="chartLoaded"
            :chartData="doughnutDataChart"
            :chartOptions="resultDoughnutChartOptions"
            class="doughnut-chart"
          />
        </div>
        <div class="result-products-wrapper">
          <div
            class="result-products-item"
            v-for="(item, index) in doughnutDataChart.labels"
            :key="item"
          >
            <div
              class="label-color"
              :style="{ backgroundColor: doughnutDataChart.datasets[0].backgroundColor[index] }"
            ></div>
            <VueText
              color="grey-40"
              sizeLevel="6"
              weightLevel="3"
              class="result-products-item-text"
              >{{ item }}</VueText
            >
          </div>
        </div>
      </div>
    </BrandResultView>
    <div v-if="openInfoPopUp" class="revenue-distribution-info-holder">
      <div class="revenue-distribution-info-wrapper">
        <VueText
          class="revenue-distribution-info-title"
          color="grey-50"
          sizeLevel="9"
          weightLevel="3"
          >Kategorilere Göre Ciro Dağılımı</VueText
        >
        <VueText class="revenue-distribution-info-text" color="grey-50" sizeLevel="6">{{
          modalText
        }}</VueText>
        <BrandButton
          class="close-button"
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          :padding="{ vertical: 15, horizontal: 0 }"
          @click="handlePopUpState('close')"
          >KAPAT</BrandButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import BrandDoughnutChart from '@/components/brand/BrandChart/BrandDoughnutChart.vue';
import BrandResultView from '@/components/brand/BrandResultView/BrandResultView.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
// import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import { Learn } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'Turnover',
  mixins: [gtmUtils],
  components: {
    VueForm,
    VueInput,
    // VueIcon,
    BrandButton,
    BrandDoughnutChart,
    BrandResultView,
    VueText,
    VueWrapperNarrow,
  },
  data() {
    return {
      chartDataToRender: [],
      renderKey: 0,
      chartLoaded: true,
      modalText: '',
      openInfoPopUp: false,
      showResultView: false,
      isDisabled: true,
      formModel: {
        philipMorrisProducts: {
          name: 'philipMorrisProducts',
          label: 'Philip Morris Ürünleri',
          amount: '',
        },
        stapleFood: { name: 'stapleFood', label: 'Temel Gıda Ürünleri', amount: '' },
        nutsAndSnacks: { name: 'nutsAndSnacks', label: 'Kuruyemiş ve Çerez', amount: '' },
        alcoholicBeverages: { name: 'alcoholicBeverages', label: 'Alkollü İçeçekler', amount: '' },
        nonAlcoholicBeverages: {
          name: 'nonAlcoholicBeverages',
          label: 'Alkolsüz İçeçekler',
          amount: '',
        },
        cleaningProducts: { name: 'cleaningProducts', label: 'Temizlik Ürünleri', amount: '' },
        productsFromOtherCompanies: {
          name: 'productsFromOtherCompanies',
          label: 'Diğer Sigara Firma Ürünleri',
          amount: '',
        },
        newspaperAndMagazine: {
          name: 'newspaperAndMagazine',
          label: 'Gazete ve Dergi',
          amount: '',
        },
        biscuitAndChocolates: {
          name: 'biscuitAndChocolates',
          label: 'Bisküvi ve Çikolatalar',
          amount: '',
        },
        others: { name: 'others', label: 'Diğer' },
      },
      headerContent: [
        {
          headline: 'Ciro dağılımınızı öğrenin',
          text: 'Aşağıdaki alanlara her bir kategori için günlük ortalama cironuzu giriniz. (TL)',
        },
      ],

      doughnutDataChart: {
        labels: [],
        datasets: [
          {
            label: 'Ciro Dağılımı',
            backgroundColor: [
              '#50d2c2',
              '#e5472d',
              '#b80342',
              '#f58011',
              '#e74a2c',
              '#00c3ff',
              '#1c638d',
              '#d3650e',
              '#579653',
              '#7c38bc',
            ],
            data: [],
          },
        ],
      },
      doughnutCategoryChart: {
        labels: [],
        datasets: [
          {
            label: 'Ciro Dağılımı',
            backgroundColor: ['#50d2c2', '#e5472d', '#b80342', '#f58011'],
            data: [],
          },
        ],
      },
      doughnutChartOptions: {
        rotation: Math.PI * 0.751,
        circumference: (Math.PI * 3) / 2,
        cutoutPercentage: 90,
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            padding: 20,
          },
        },
        tooltips: {
          mode: 'label',
          backgroundColor: '#ffffff',
          titleFontColor: '#23303d',
          bodyFontColor: '#23303d',
          titleFontStyle: 'normal',
          bodyFontStyle: 'bold',
        },
      },
      resultDoughnutChartOptions: {
        rotation: -90,
        cutout: '60%',
        plugins: {
          datalabels: {
            color: 'white',
            formatter: value => {
              if (!value) {
                return '';
              }
              return `${value} %`;
            },
            font: {
              size: 14,
            },
          },
          legend: {
            display: false,
          },
        },
        tooltips: {
          mode: 'label',
          backgroundColor: '#ffffff',
          titleFontColor: '#23303d',
          bodyFontColor: '#23303d',
          titleFontStyle: 'normal',
          bodyFontStyle: 'bold',
        },
      },
    };
  },
  computed: {
    getCategoryPercentage() {
      let sum = this.doughnutCategoryChart.datasets[0].data.reduce((total, num) => {
        return total + num;
      }, 0);
      return `% ${sum}`;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    icons() {
      return ICON_VARIABLES;
    },
  },
  created() {
    this.setBottomBarStatus(false);
  },
  beforeCreate() {
    Learn.getRevenueDistribution().then(res => {
      let response = res.data.Data;
      this.modalText = response.title;
      let revenues = response.revenues;

      if (response && revenues && revenues.length > 0) {
        for (let category of revenues) {
          this.doughnutCategoryChart.datasets[0].data.push(category.value);
          this.doughnutCategoryChart.labels.push(category.title);
        }
        this.renderKey += 1;
      }
    });
  },
  mounted() {
    this.fetchLabelData().then(() => {
      if (this.doughnutDataChart.labels.length || this.doughnutCategoryChart.labels.length) {
        this.renderKey += 1;
      }
    });
  },
  destroyed() {
    this.setBottomBarStatus(true);
  },
  methods: {
    async calculateResultChartData() {
      this.doughnutDataChart.datasets[0].data = [];
      this.doughnutDataChart.labels = [];

      let total = 0;
      Object.values(this.formModel).forEach(item => {
        total += item.amount ? Number(item.amount) : 0;
      });

      Object.values(this.formModel).forEach(item => {
        const percentage = item.amount ? ((Number(item.amount) / total) * 100).toFixed(0) : 0;
        this.doughnutDataChart.datasets[0].data.push(percentage);
        this.doughnutDataChart.labels.push(item.label);
      });

      try {
        let pushData = {
          event: 'product_revenue_calculation',
          philip_morris: `%${(
            (100 * Number(this.formModel.philipMorrisProducts.amount)) /
            total
          ).toFixed(2)}`,
          temel_gida: `%${((100 * Number(this.formModel.stapleFood.amount)) / total).toFixed(2)}`,
          kuruyemis_cerez: `%${(
            (100 * Number(this.formModel.nutsAndSnacks.amount)) /
            total
          ).toFixed(2)}`,
          alkollu_icecekler: `%${(
            (100 * Number(this.formModel.alcoholicBeverages.amount)) /
            total
          ).toFixed(2)}`,
          temizlik_malzemeleri: `%${(
            (100 * Number(this.formModel.cleaningProducts.amount)) /
            total
          ).toFixed(2)}`,
          diger_sigara_firma_urunleri: `%${(
            (100 * Number(this.formModel.productsFromOtherCompanies.amount)) /
            total
          ).toFixed(2)}`,
          biskuvi_cikolata: `%${(
            (100 * Number(this.formModel.biscuitAndChocolates.amount)) /
            total
          ).toFixed(2)}`,
          alkolsuz_icecek: `%${(
            (100 * Number(this.formModel.nonAlcoholicBeverages.amount)) /
            total
          ).toFixed(2)}`,
          gazete_dergi: `%${(
            (100 * Number(this.formModel.newspaperAndMagazine.amount)) /
            total
          ).toFixed(2)}`,
          diger: `%${((100 * Number(this.formModel.others.amount)) / total).toFixed(2)}`,
        };
        this.pushDataLayerEvent('learn', pushData);
      } catch (err) {
        console.error(err, 'Turnover - Google analytics, iProspect error');
      }
    },
    async fetchLabelData() {
      const elements = document.getElementsByClassName('form-input');
      for (let element of elements) {
        this.doughnutDataChart.labels.push(element.innerText);
      }
    },
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    async handleSubmit() {
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        this.calculateResultChartData().then(() => {
          this.showResultView = true;
        });
      }
    },
    closeModal() {
      this.showResultView = false;
      this.doughnutDataChart.datasets[0].data.splice(
        0,
        this.doughnutDataChart.datasets[0].data.length,
      );
      if (!this.showResultView) {
        document.documentElement.style.overflow = 'auto';
      }
    },
    submitResultPopUp() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.ManageMyBusiness.path}`);
    },
    handlePopUpState(action) {
      if (action === 'open') {
        this.openInfoPopUp = true;
      }
      if (action === 'close') {
        this.openInfoPopUp = false;
      }
    },
  },
  watch: {
    formModel: {
      deep: true,
      handler() {
        let count = 0;
        Object.keys(this.formModel).forEach(key => {
          if (this.formModel[key].amount && this.formModel[key].amount.length > 0) {
            count++;
          }
          this.isDisabled = count === 0;
        });
      },
    },
  },
};
</script>

<style scoped lang="scss">
.turnover-header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: palette-space-level(60);
  padding: 50px;
  padding-left: palette-space-level(20);
  background-color: palette-color-level('grey', '10');
  .turnover-header-left {
    flex-basis: 45%;
    margin: auto 0;

    .header-content {
      // margin-bottom: palette-space-level(20);

      .header-content-headline {
        line-height: 0.92;
        margin-bottom: palette-space-level(10);
      }

      .header-content-text {
        line-height: 1.22;
      }
    }

    .doughnut-chart-products {
      display: flex;
      flex-direction: column;
      background-color: #f7f8fa;

      .label-color {
        border-radius: palette-radius-level('0');
        width: palette-space-level(15);
        height: palette-space-level(15);
      }

      .products-item {
        display: flex;
        align-items: center;
        padding: palette-space-level(10) 0;

        .products-item-text {
          margin-left: palette-space-level(10);
        }
      }
    }
  }

  .turnover-header-right {
    margin: auto;

    .header-with-chart-wrapper {
      padding: palette-space-level(30) palette-space-level(20);
      border: 1px solid palette-color-level('grey', '20');
      border-radius: palette-radius-level(4);
      width: palette-space-level(400);

      .doughnut-wrapper {
        max-width: 50%;
        margin: auto;
        position: relative;

        .mock-text {
          position: absolute;
          left: 45%;
          top: calc(50% + 10px);
          transform: translate(-50%, -50%);
        }
      }

      .doughnut-headline {
        display: flex;
        justify-content: center;
        padding-top: palette-space-level(20);

        .doughnut-headline-text {
          line-height: 1.38;
          padding-right: palette-space-level(5);
        }
      }
    }
  }
}

.form-holder {
  margin-top: palette-space-level(60);

  .form-input {
    margin-bottom: palette-space-level(20);
  }
}

.calc-button {
  margin-top: palette-space-level(10);
}

.result-doughnut-products-wrapper {
  display: flex;
  margin: palette-space-level(100) 0 palette-space-level(70);
  justify-content: center;
  align-items: center;

  .result-doughnut-wrapper {
    position: relative;
    max-width: 35%;
    margin: 0 palette-space-level(50);
  }
  .result-products-wrapper {
    flex-direction: column;
    margin: 0 palette-space-level(80);

    .result-products-item {
      display: flex;
      align-items: center;
      padding: palette-space-level(15) 0;

      .label-color {
        border-radius: palette-radius-level('0');
        width: palette-space-level(15);
        height: palette-space-level(15);
      }

      .result-products-item-text {
        margin-left: palette-space-level(10);
      }
    }
  }
}

.modal {
  line-height: 1.86;
}

.revenue-distribution-info-holder {
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(3px);

  .revenue-distribution-info-wrapper {
    width: 460px;
    border-radius: palette-radius-level(2);
    margin: auto;
    flex-direction: column;
    text-align: center;
    background-color: palette-color-level('white', '100');

    .revenue-distribution-info-title {
      padding: palette-space-level(30) 0 palette-space-level(20);
      line-height: 2;
    }

    .revenue-distribution-info-text {
      padding: 0 palette-space-level(30) palette-space-level(30);
      line-height: 1.33;
    }

    .close-button {
      bottom: 0;
    }
  }
}
</style>
